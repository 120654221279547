<template>
  <b-overlay :show="loading">
    <div>
      <!-- <transactions-list-filter
        v-if="showFilters"
        :transaction-type.sync="transactionTypeFilter"
        :date-start.sync="dateStart"
        :date-end.sync="dateEnd"
        :transaction-type-options="transactionTypeOptions"
      /> -->

      <b-card
        no-body
        class="mb-0 mt-5"
      >
        <b-table
          ref="refDataTable"
          class="position-relative"
          table-class="transactionsTable"
          :items="transactions"
          :fields="tableColumns"
          responsive
          show-empty
          primary-key="id"
          empty-text="No matching records found"
          tbody-tr-class="table-row"
          @row-clicked="onRowClicked"
        >
          <template #cell(status)="data">
            <b-badge
              pill
              :variant="`light-${resolveStatusVariant(data.item.status)}`"
              class="text-capitalize"
            >
              {{ getUserStatusText(data.item.status) }}
            </b-badge>
          </template>
        </b-table>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import {
  BMedia, BAvatar, BButton, VBTooltip,
  BOverlay, BCard, BRow, BCol, BAlert, BLink, BTable,
  BBadge, BDropdown, BDropdownItem, BPagination
} from 'bootstrap-vue'

import { get } from "lodash"
import { formatDate, formatMoney, formatTime } from "@/@core/utils/utils"

import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const watchHandler = {
  handler(){
    this.fetchAllData()
  }, 
  immediate: false
}

const watchHandlerRequiresPageReset = {
  handler(){
    this.currentPage = 1;
    this.fetchAllData()
  },
  immediate: false
}

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BOverlay,
    BCard,
    BTable,
    BRow,
    BCol,
    BAlert,
    BBadge,
    BLink,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,

    vSelect,
  },
  props: {
    tableColumns: {
      type: Array,
      default: () => {
          return [
          { key: 'event.title', label: 'Event', sortable: false },
          { key: 'accommodation.title', label: 'Accommodation', sortable: false },
          { key: 'event.event_start_date', formatter: val => `${formatDate(val)}`, label: 'Start Date', sortable: false },
          { key: 'event.event_start_time', formatter: val => `${formatTime(val)}`, label: 'Start Time', sortable: false },
          { key: 'price', sortable: false, label: 'Price', formatter: val => `¢${formatMoney(val)}` },
          { key: 'status', sortable: false },
          { key: 'created', sortable: false, formatter: val => `${formatDate(val)}`, label: 'Date', },
        ]
      }
    },
  },
  data(){
    return {
      loading: false,
      search: "",
      transactions: [],
    }
  },
  computed: {
    perPage: {
      get() {
        return this.$store.getters[`navigation/recordsPerPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_RECORDS_PER_PAGE`, value)
      }
    },
    currentPage: {
      get() {
        return this.$store.getters[`navigation/currentPage`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_CURRENT_PAGE_NUMBER`, value)
      }
    },
    totalRecords: {
      get(){
        return this.$store.getters[`navigation/totalRecords`];
      },
      set(value){
        this.$store.commit(`navigation/UPDATE_TOTAL_RECORDS`, value)
      }
    },
  },
  watch: {
    search: watchHandler,
    currentPage: watchHandlerRequiresPageReset,
    perPage: watchHandlerRequiresPageReset,
  },
  created(){
    this.fetchAllData();
  },
  methods: {
    async fetchAllData() {
      try {
        this.loading = true; 

        const query = {
          limit: this.perPage,
          page: this.currentPage,
        }

        const request = await this.useJwt().clientService.fetchReservations(query);
        const { data, pagination } = request.data;
        
        this.totalRecords = pagination.totalRecords
        this.perPage = pagination.limit;

        this.transactions = data;
      } catch (error) {
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    onRowClicked(rowItem){
      this.$router.push({ name: 'reservation-details', params: { reservation_id: rowItem._id } })
      .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-select.scss';
  .width-100 {
    width: 100px;
  }
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  .table-row {
    cursor: pointer !important;
  }
  // .transactionsTable td:nth-child(2) {
  //   background-color: dodgerblue !important;
  //   color: black;
  // } 
</style>
